import React from 'react';
import BrandLogo from '../BrandLogo';
import { default as NavLink } from './Button';

interface HeaderProps {
  
}

const Header: React.FC<HeaderProps> = () => {
    const [isActived, setIsActived] = React.useState('Home');
    const navLinks = [
        {
            name: 'Home',
            link: 'Home'
        },
        {
            name: 'Skill',
            link: 'Skill'
        },
        // {
        //     name: 'Experience',
        //     link: 'Experience'
        // },
        {
            name: 'Education',
            link: 'Education'
        },
        // {
        //     name: 'Resume',
        //     link: 'Resume'
        // },
        {
            name: 'Blog',
            link: 'Blog'
        },
        {
            name: 'Contact',
            link: 'Contact'
        }
    ];

    const handleActive = (name: string) => {
        setIsActived(name);
    }

    return (
      <div className={`py-6 px-10 flex items-center justify-between max-md:justify-center max-h-[90px]`}>
        <BrandLogo link="/">Xenwithu</BrandLogo>
        <div className={`max-md:hidden flex flex-wrap justify-between font-semibold `}>
            {navLinks.map((navLink, index) => (
                <NavLink
                    key={index}
                    isActve={navLink.name === isActived}
                    link={navLink.link}
                    onClick={() => handleActive(navLink.name)}
                >
                    {navLink.name}
                </NavLink>
            ))}
        </div>
      </div>
    )
}

export default Header;