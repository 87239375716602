import SectionHeader from "../../pages/PortfolioPage/components/SectionHeader";
import React from "react";

interface EducationProps {
}

const EducationSection: React.FC<EducationProps> = (props) => (
        <div className={`flex flex-col items-center  justify-center  my-14`}>
            <div className={`my-5`}>
                <SectionHeader title={`Education`} subTitle={`Master, Engineer and Bachelor`}/>
            </div>
            {/* Body */}
            <div>
                <div className={`flex my-10 justify-between items-center shadow-primary p-5 rounded-md`}>
                    <div className={`flex items-center `}>
                        <div className={`mr-10`}>
                            <img
                                className={`p-3 w-32 max-sm:w-64 shadow-primary rounded-md`}
                                src="./img/logo-itf-dut.jpg"
                                alt="avatar"
                            />
                        </div>
                        <div className={`flex flex-col`}>
                            <div className={`mb-7`}>
                                <span className={`px-3 py-2 shadow-primary rounded-full`}>2020-Present</span>
                            </div>
                            <h2 className={`text-2xl font-semibold`}>The University of Danang - University of Science
                                and
                                Technology</h2>
                            <div>Information Technology Faculty</div>
                        </div>
                    </div>
                </div>
                <div className={`flex my-10 justify-between items-center shadow-primary p-5 rounded-md`}>
                    <div className={`flex items-center `}>
                        <div className={`mr-10`}>
                            <img
                                className={`p-3 w-32 max-sm:w-64 shadow-primary rounded-md`}
                                src="./img/logo-harvard.png"
                                alt="avatar"
                            />
                        </div>
                        <div className={`flex flex-col`}>
                            <div className={`mb-7`}>
                                <span className={`px-3 py-2 shadow-primary rounded-full`}>In My Dream</span>
                            </div>
                            <h2 className={`text-2xl font-semibold`}>Harvard University</h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
);

export default EducationSection;
