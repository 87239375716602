import React from "react";
import {Link} from "react-router-dom";

const Wiki: React.FC = () => {
    return (
        <>
            <div>wiki home</div>
            <div>
                <h2>Pick up where you left off</h2>
                <div className={`flex flex-col border`}>
                    <Link to={ "/wiki/spages/page1" }  >Page 1</Link>
                    <Link to={ "/wiki/spages/page2" }  >Page 2</Link>
                    <Link to={ "/wiki/spages/page3" }  >Page 3</Link>
                    <Link to={ "/wiki/spages/page4" }  >Page 4</Link>
                    <Link to={ "/wiki/spages/page5" }  >Page 5</Link>
                </div>
            </div>
            <div className={`border`}>
                <div>Spaces</div>
                <div>
                    <Link to={"./spaces/xenwel"} >XenW/EL</Link>
                    <Link to={"./spaces/xenwproj"} >XenW/Proj</Link>
                </div>
            </div>
        </>
    );
};

export default Wiki;
