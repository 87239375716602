import React, {useState} from "react";
import SectionHeader from "../../pages/PortfolioPage/components/SectionHeader";
import {MdOutlinePhoneInTalk} from "react-icons/md";
import {LuMail} from "react-icons/lu";
import {Link} from "react-router-dom";
import {FaGithub, FaGitlab, FaTelegram} from "react-icons/fa";
import {FaLinkedinIn} from "react-icons/fa6";
import useContact from "../../hooks/useContact";

interface FormValues {
    name: string;
    email: string;
    tel: number|'';
    subject: string;
    message: string;
}

interface SubmitStatus {
    status: boolean;
    message: string;
}

interface EducationProps {
}

const EducationSection: React.FC<EducationProps> = (props) => {
    const { sendEmail } = useContact();

    const [formValues, setFormValues] = useState<FormValues>({
        name: '',
        email: '',
        tel: '',
        subject: '',
        message: '',
    });

    const [loading, setLoading] = useState<boolean>(false);

    const [submitStatus, setSubmitStatus] = useState<SubmitStatus>({
        status: true,
        message: '',
    });

    const handleChange = ( e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setFormValues({
            ...formValues,
            [name]: value,
        });
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        console.log("Form Submitted:", formValues);
        console.log({
            serviceId: process.env.REACT_APP_EMAILJS_SERVICE_ID!,
            templateId: process.env.REACT_APP_EMAILJS_TEMPLATE_ID!,
            templateParams: {...formValues},
            publicKey: process.env.REACT_APP_EMAILJS_PUBLIC_KEY!
        });
        setLoading(true);
        sendEmail(process.env.REACT_APP_EMAILJS_SERVICE_ID!, process.env.REACT_APP_EMAILJS_TEMPLATE_ID!, {...formValues}, process.env.REACT_APP_EMAILJS_PUBLIC_KEY!).then(() => {
            setLoading(false);
            setFormValues({
                name: '',
                email: '',
                tel: '',
                subject: '',
                message: '',
            });
            setSubmitStatus({
                status: true,
                message: 'Message sent successfully<3',
            })
        }).catch((error) => {
            setLoading(false);
            setSubmitStatus({
                status: false,
                message: "Something went wrong, please try again later",
            })
        })
        // Thực hiện các hành động khác như gửi dữ liệu lên server
    };
    return (
        <div className={`flex flex-col justify-center p-3 md:mx-20 mt-10`}>
            <div className={`mb-10`}>
                <SectionHeader title={`Contact`} subTitle={`Contact With Me`}/>
            </div>
            <div className={`flex justify-center`}>
                {/* information */}
                <div className={`flex flex-col max-w-80  items-center shadow-primary rounded-xl p-10`}>
                    <div className={`flex flex-col items-center `}>
                        <img
                            className={`w-24 max-sm:w-64 shadow-primary rounded-full`}
                            src="./img/avatar.png"
                            alt="avatar"
                        />
                    </div>
                    <div className={`mb-3`}>
                        Le Van Dat
                    </div>
                    <p className={`flex mb-3`}>I’m currently looking for job opportunities. Feel free to connect with me
                        through my account.</p>
                    <div className={`mb-3 flex flex-col items-center`}>
                        <div className={`flex items-center pb-2 font-medium`}>
                            <MdOutlinePhoneInTalk/>
                            <a className={`ml-1`} href="tel:+84378900215"> +84378900215</a>
                        </div>
                        <div className={`flex items-center pb-2  font-medium`}>
                            <LuMail/>
                            <a className={`ml-1`} href="mailto:lvd.levandat@gmail.com"> lvd.levandat@gmail.com</a>
                        </div>
                    </div>
                    <div className={`flex bg-none text-5xl`}>
                        <div className={`shadow-primary rounded-md mx-3 p-3`}>
                            {/*<TbBrandFacebook/>*/}
                        </div>

                        <div className={`shadow-primary rounded-md mx-3 p-3`}>
                            {/*<TbBrandInstagram/>*/}
                        </div>

                        <div className={`shadow-primary rounded-md mx-3 p-3`}>
                            {/*<TbBrandLinkedin/>*/}
                        </div>
                    </div>
                </div>
                {/* contact */}
                <form
                    className={`flex flex-col p-10 shadow-primary mx-5 rounded-xl`}
                    onSubmit={handleSubmit}
                >
                    <div className={`flex`}>
                        <div>
                            <div>Your Name<label className={`text-blue-600 ml-1`}>*</label></div>
                            <input
                                type="text"
                                name={`name`}
                                value={formValues.name}
                                onChange={handleChange}
                                className={`border-2 border-light-primary-bg rounded-md p-2`}
                                required={true}
                            />
                        </div>
                        <div>
                            <div>Phone Number</div>
                            <input
                                type="tel"
                                name={`tel`}
                                value={formValues.tel}
                                onChange={handleChange}
                                className={`border-2 border-light-primary-bg rounded-md p-2`}
                            />
                        </div>
                    </div>
                    <div>
                        <div>Your Email<label className={`text-blue-600 ml-1`}>*</label></div>
                        <input
                            type="email"
                            name={`email`}
                            value={formValues.email}
                            onChange={handleChange}
                            className={`w-full border-2 border-light-primary-bg rounded-md p-2`}
                            required={true}
                        />
                    </div>
                    <div>
                        <div>Subject<label className={`text-blue-600 ml-1`}>*</label></div>
                        <input
                            name={`subject`}
                            value={formValues.subject}
                            onChange={handleChange}
                            type="text"
                            className={`w-full border-2 border-light-primary-bg rounded-md p-2`}
                            required={true}
                        />
                    </div>

                    {/* content */}
                    <div>
                        <div>Content<label className={`text-blue-600 ml-1`}>*</label></div>
                        <textarea
                            name={`message`}
                            value={formValues.message}
                            onChange={handleChange}
                            className={`w-full border-2 border-light-primary-bg rounded-md p-2 mb-3`}
                            required={true}
                        />
                    </div>
                    <div className={`flex items-center w-full`}>
                        {/*input submit*/}
                        <input
                            type="submit"
                            value={loading ? 'Sending...' : 'Send'}
                            className={`flex flex-row items-center px-10 py-5 my-3 rounded-xl shadow-primary cursor-pointer`}
                        />
                        <div className={`flex items-center justify-center w-full`}>
                            <span>{submitStatus.message ? submitStatus.message : ''}</span></div>

                    </div>
                </form>

            </div>
            <div className={`flex flex-row items-center justify-center my-10 bg-none text-3xl`}>
                <div className={`font-light shadow-primary rounded-full p-3 mx-3`}>
                    <Link to={'https://github.com/levandattt'} target={'_blank'}>
                        <FaGithub/>
                    </Link>
                </div>
                <div className={`shadow-primary rounded-full mx-3 p-3`}>
                    <Link to={'https://gitlab.com/xenwithu'} target={'_blank'}>
                        <FaGitlab/>
                    </Link>
                </div>
                <div className={`shadow-primary rounded-full mx-3 p-3`}>
                    <Link to={'https://www.linkedin.com/in/xenwithzoo/'} target={'_blank'}>
                        <FaLinkedinIn/>
                    </Link>
                </div>
                <div className={`shadow-primary rounded-full mx-3 p-3`}>
                    <Link to={'https://t.me/xenwithu'} target={'_blank'}>
                        <FaTelegram/>
                    </Link>
                </div>
            </div>
        </div>

    );
};

export default EducationSection;