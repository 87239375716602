import SectionHeader from "../../pages/PortfolioPage/components/SectionHeader";
import React from "react";
import {skills} from '../../constants/skills';
import SkillCard from "../CardItem";
import {SkillType} from "../../enums";

interface EducationProps {
}

const SkillSection: React.FC<EducationProps> = (props) => {
    return (
        <div className={`flex flex-col items-center  justify-center  my-14`}>
            <div className={`my-5`}>
                <SectionHeader title={`Skills & Expertise`} subTitle={`My Skills`}/>
            </div>

            {/* backend */}

            <div className={`grid grid-cols-1 gap-5`}>
                <div>
                    <div className={`my-5`}>
                        <h1 className={`font-semibold text-3xl border-l-4 pl-2`}>Backend</h1>
                    </div>
                    <div className={`grid grid-cols-1 md:grid-cols-4 gap-10`}>
                        {
                            skills.filter(skill => skill.type === SkillType.BACK_END).map(skill => (
                                <SkillCard skill={skill} key={skill.name}/>
                            ))
                        }
                    </div>
                </div>
                <div>
                    <div className={`my-5`}>
                        <h1 className={`font-semibold text-3xl border-l-4 pl-2`}>Database</h1>
                    </div>
                    <div className={`grid grid-cols-1 md:grid-cols-4 gap-10`}>
                        {
                            skills.filter(skill => skill.type === SkillType.DATABASE).map(skill => (
                                <SkillCard skill={skill} key={skill.name}/>
                            ))
                        }
                    </div>
                </div>
                <div>
                    <div className={`my-5`}>
                        <h1 className={`font-semibold text-3xl border-l-4 pl-2`}>Frontend</h1>
                    </div>
                    <div className={`grid grid-cols-1 md:grid-cols-4 gap-10`}>
                        {
                            skills.filter(skill => skill.type === SkillType.FRONT_END).map(skill => (
                                <SkillCard skill={skill} key={skill.name}/>
                            ))
                        }
                    </div>
                </div>
                <div>
                    <div className={`my-5`}>
                        <h1 className={`font-semibold text-3xl border-l-4 pl-2`}>Tools</h1>
                    </div>
                    <div className={`grid grid-cols-1 md:grid-cols-4 gap-10`}>
                        {
                            skills.filter(skill => skill.type === SkillType.TOOLS).map(skill => (
                                <SkillCard skill={skill} key={skill.name}/>
                            ))
                        }
                    </div>
                </div>
            </div>

        </div>
    )
}

export default SkillSection;
