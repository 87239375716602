import React from 'react';
import { Link } from "react-scroll";

interface ButtonProps {
    children: React.ReactNode;
    link: string;
    disabled?: boolean;
    isActve?: boolean;
    onClick?: () => void;
}

const Button: React.FC<ButtonProps> = (props) => (
    <Link
        activeClass="active"
        className={`px-3 mx-2 flex hover:shadow-primary hover:bg-light-primary-bg-alpha ${props.isActve?'bg-light-primary-bg-alpha shadow-primary':''} rounded-md py-3`}
        to={props.link}
        spy={true}
        smooth={true}
        offset={-150}
        duration={500}
        onClick={props.onClick}
    >
        <h2 className={`cursor-pointer select-none`}>{props.children}</h2>
    </Link>
);

export default Button;