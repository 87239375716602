import React from "react";
import {Outlet} from "react-router-dom";

const WikiSpaces: React.FC = () => {
    return (
        <Outlet/>
    );
};

export default WikiSpaces;
