import React from "react";
import {Link} from "react-router-dom";

const WikiSpacesHome: React.FC = () => {
    return (
        <div className={`border`}>
            <div>Spaces</div>
            <div className={`flex flex-col border`}>
                <Link to={"./xenwel"}>XenW/EL</Link>
                <Link to={"./xenwproj"}>XenW/Proj</Link>
            </div>
        </div>
    );
};

export default WikiSpacesHome;
