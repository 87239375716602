import React from "react";
import {useParams} from "react-router-dom";

interface EventParams extends Record<string, string | undefined> {
    id: string;
}


const WikiPage: React.FC = () => {

  const { id } = useParams<EventParams>();

    return (
        <>
            Page {id}
        </>
    );
};

export default WikiPage;
