import React from "react";
import {Link,useParams} from "react-router-dom";

interface EventParams extends Record<string, string | undefined> {
    spaceName: string;
}
const WikiSpaces: React.FC = () => {
    const { spaceName } = useParams<EventParams>();
    return (
        <>
            <div>Đây là Space {spaceName}</div>
            <div>
                <h2>Pick up where you left off</h2>
                <div className={`flex flex-col border`}>
                    <Link to={"./page1"}>Page 1</Link>
                    <Link to={"./page2"}>Page 2</Link>
                    <Link to={"./page3"}>Page 3</Link>
                    <Link to={"./page4"}>Page 4</Link>
                    <Link to={"./page5"}>Page 5</Link>
                </div>
            </div>
        </>
    );
};

export default WikiSpaces;
