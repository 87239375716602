
import React from "react";

interface ImageProps {
    id: string;
    image: string;
}

interface BannerProps {
    images: ImageProps[];
    speed?: number;
}

const Sliderinfinity: React.FC<BannerProps> = ({ images, speed = 5000 }) => {
    return (
        <div className="relative w-full overflow-hidden h-20 select-none ">
            <div className="absolute flex">
                {[...Array(3)].map((_, index) => (
                    <section
                        key={index}
                        className="flex animate-swipe"
                        style={{
                            animationDuration: `${speed}ms`,
                        }}
                    >
                        {images.map(({ id, image }) => (
                            <div className="shrink-0" key={id}>
                                <img
                                    src={image}
                                    alt={id}
                                    className="max-w-[150px] h-20 object-cover px-4 last:pl-0 px-8"
                                />
                            </div>
                        ))}
                    </section>
                ))}
            </div>
        </div>
    );
};

export default Sliderinfinity;