import React from "react";
import Header from "./components/Header";

import EducationSection from "../../components/Section/EducationSection";
import {Element, Link} from "react-scroll";
import HomeSection from "../../components/Section/HomeSection";
import ContactSection from "../../components/Section/ContactSection";
import {FaChevronUp} from "react-icons/fa6";
import { v4 as uuidv4 } from 'uuid';
import SliderInfinity from "../../components/SliderInfinity";
import SkillSection from "../../components/Section/SkillSection";


const PortfolioPage: React.FC = () => {
    const images = [
        "https://upload.wikimedia.org/wikipedia/commons/4/4c/Typescript_logo_2020.svg",
        "https://nodejs.org/static/logos/nodejsStackedLight.svg",
        "https://upload.wikimedia.org/wikipedia/commons/a/a7/React-icon.svg",
        "https://upload.wikimedia.org/wikipedia/vi/3/30/Java_programming_language_logo.svg",
        "https://upload.wikimedia.org/wikipedia/commons/7/79/Spring_Boot.svg",
        "https://www.mysql.com/common/logos/logo-mysql-170x115.png",
        "https://wiki.postgresql.org/images/9/9a/PostgreSQL_logo.3colors.540x557.png",
        "https://www.vectorlogo.zone/logos/redis/redis-icon.svg",
        "https://s3.dualstack.us-east-2.amazonaws.com/pythondotorg-assets/media/community/logos/python-logo-only.png"
    ].map((image) => ({
        id: uuidv4(),
        image
    }));
  return (
    <div className="bg-light-primary-bg w-screen overflow-auto">
      <div className={`fixed bottom-0 right-0`}>
        <Link
            activeClass="active"
            to={'Home'}
            spy={true}
            smooth={true}
            duration={500}
        >
          <h2 className={`bg-light-white shadow-primary cursor-pointer select-none p-5 m-5 bg-slate-100 rounded-full `}><FaChevronUp /></h2>
        </Link>
      </div>
      {/* header */}
      <div className={`fixed w-full z-50 top-0 shadow-primary backdrop-blur-sm`}>
        <Header />
      </div>
      {/* Body */}
        <div className={``}>
            {/* Home Section*/}
            <Element name="Home" className="element md:mx-20">
                <HomeSection/>
            </Element>

            <div className={`border-t border-light-line-bg `}></div>
            <div className={`my-10`}>
                <SliderInfinity images={images} speed={5000}/>
            </div>
            <div className={`border-t border-light-line-bg`}></div>

            <Element name="Skill" className="element md:mx-20">
                <SkillSection/>
            </Element>

            <div className={`border-t border-light-line-bg `}></div>

            {/* Education Section */}
            <Element name="Education" className="element md:mx-20">
                <EducationSection/>
            </Element>

            {/* Blog Section */}
            <div className={`border-t border-light-line-bg `}></div>

            {/* Contact */}
            <Element name="Contact" className="element md:mx-20">
                <ContactSection/>
            </Element>
        </div>

        {/* footer */}
        <div className={`flex flex-col justify-center p-5 md:mx-20`}>

        </div>
    </div>
  );
};

export default PortfolioPage;
