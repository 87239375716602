import { useState } from 'react';
import emailjs from '@emailjs/browser';

interface EmailStatus {
    loading: boolean;
    success: boolean | null;
    error: string | null;
}

const useEmail = () => {
    const [status, setStatus] = useState<EmailStatus>({
        loading: false,
        success: null,
        error: null,
    });

    const sendEmail = async (
        serviceId: string,
        templateId: string,
        templateParams: Record<string, unknown>,
        publicKey: string
    ) => {
        setStatus({ loading: true, success: null, error: null });

        try {
            const response = await emailjs.send(serviceId, templateId, templateParams, {
                publicKey,
            });
            console.log('SUCCESS!', response.status, response.text);
            setStatus({ loading: false, success: true, error: null });
        } catch (error) {
            console.error('FAILED...', error);
            setStatus({ loading: false, success: false, error: (error as Error).message });
        }
    };

    return { sendEmail, status };
};

export default useEmail;
