import React from 'react';

interface SkillCardProps {
    skill: Skill;
}

const SkillCard: React.FC<SkillCardProps> = (props) => {

    return (
        <div className={`relative flex items-center p-5 bg-light-primary-bg rounded-lg shadow-primary`}>
            <div className={`m-3 text-7xl`}>
                <props.skill.icon />
            </div>
            <div className={`px-3`}>
                <h2 className={`font-bold text-lg mb-1`}>{props.skill.name}</h2>
                <div className={``}>
                    <p>{props.skill.year} year</p>
                    <p className={`italic`}>{props.skill.level}</p>
                </div>
            </div>
            {
                props.skill.mark && (
                    <div className={`absolute top-0 right-0 `}>
                        <p>props.skill.mark</p>
                    </div>
                )
            }
        </div>
    )
}

export default SkillCard;