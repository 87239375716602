import {RouterProvider, createBrowserRouter, RouteObject, Outlet} from "react-router-dom";
import PortfolioPage from "../pages/PortfolioPage/";
import Wiki from "../pages/Wiki";
import WikiSpaces from "../pages/Wiki/WikiSpaces";
import WikiHome from "../pages/Wiki/WikiHome";
import WikiSpacesHome from "../pages/Wiki/WikiSpaces/WikiSpacesHome/WikiSpacesHome";
import WikiSpace from "../pages/Wiki/WikiSpaces/WikiSpace";
import WikiPage from "../pages/Wiki/WikiSpaces/WikiSpace/WikiPage";

const Routes: React.FC = () => {

  const publicRoutes: RouteObject[] = [
    {
      path: "/",
      element: <PortfolioPage/>,
    },
    {
      path: "wiki",
      element: <Wiki/>,
      children: [
        {
          index: true,
          element: <WikiHome/>,
        },
        {
          path: "spaces",
          element: <WikiSpaces/>,
          children: [
            {
              index: true,
              element: <WikiSpacesHome/>,
            },
            {
              path: ":spaceName",
              element: <Outlet/>,
              children: [
                {
                  index: true,
                  element: <WikiSpace/>,
                },
                {
                  path: "pages",
                    element: <Outlet/>,
                    children: [
                      {
                        index: true,
                        element: <div> All contents</div>,
                      },
                      {
                        path: ":id",
                        element: <WikiPage/>,
                      }
                    ]
                }
              ]
            }
          ]
        }
      ],
    },
  ];

  // Combine and conditionally include routes based on authentication status
  const router = createBrowserRouter([
    ...publicRoutes,
  ]);

  // Provide the router configuration using RouterProvider
  return <RouterProvider router={router} />;
};

export default Routes;
