import React from "react";
import {Outlet} from "react-router-dom";

const Wiki: React.FC = () => {
    return (
        <>
            <div>wiki</div>
            <Outlet/>
        </>
    );
};

export default Wiki;
